<template>
  <div>
    {{ /*view selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      v-model="amendTradeModal"
      :title="$t('swap_future.titles.amendment_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('swap_future.modals.amend_trade_modal_ok')"
      :cancel-title="$t('swap_future.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      :no-enforce-focus="true"
      no-close-on-backdrop
    >
      <!-- :no-enforce-focus="true" sweeat alert text area çalışması için modalı böyle yapmalıyız -->

      <template #modal-header="{ close }">
        <h5 style="color: white;">
          {{ $t("swap_future.titles.amendment_trade") }}
        </h5>
        <p v-if="!checkVerify">
          <b-icon icon="exclamation-circle-fill" variant="warning"></b-icon>
          <b style="color: white;">
            {{ $t("swap_future.modals.trade_not_verified") }}
          </b>
        </p>

        <p v-else>
          <b-icon icon="bookmark-check-fill" variant="success"></b-icon>
          <b style="color: white;">
            {{ $t("swap_future.modals.verified_trade") }}
          </b>
        </p>
      </template>
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <b-row>
                <!-- left side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pl-0"
                  style="padding-right:0.5rem!important"
                >
                  <!-- /******************************* Client & Counterparty Details***************************************************************** */ -->
                  <b-card
                    :title="
                      $t('swap_future.titles.client_counterparty_details')
                    "
                  >
                    <b-row>
                      <!-- client -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('swap_future.trade_form.client')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.client')"
                            rules="required"
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                v-model="tradeForm.client"
                                ref="client"
                                :options="optClients"
                                :clearable="false"
                                :placeholder="
                                  $t('swap_future.trade_form.select_client')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :loading="loadingClient"
                                :filterable="true"
                                @option:selected="selectedClient"
                                class="compact_vue-select"
                                style="width: 100%"
                              />
                              <b-dropdown
                                variant="link"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                                class="input-dropdown animate__animated animate__fadeIn"
                                v-if="searchedClients.length > 0"
                              >
                                <template #button-content class="p-1">
                                  <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                  />
                                </template>

                                <b-dropdown-item
                                  @click="seeFilteredClientModal"
                                >
                                  <feather-icon icon="EyeIcon" />
                                  <span class="align-middle ml-50">{{
                                    $t("general_text.see_verified_clients")
                                  }}</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- client account -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.client_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.client_account')"
                            rules="required"
                          >
                            <b-form-input
                              id="clientAccount"
                              ref="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('swap_future.trade_form.client_account')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counter party -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.counterparty')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.counterparty')"
                            rules="required"
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                v-model="tradeForm.counterparty"
                                ref="cpty"
                                :options="optCounterParties"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'swap_future.trade_form.select_counterparty'
                                  )
                                "
                                :reduce="(val) => val"
                                :loading="loadingCounterparty"
                                :filterable="true"
                                @option:selected="selectedCounterparty"
                                class="compact_vue-select"
                                style="width: 100%;"
                              />
                              <b-dropdown
                                variant="link"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                                class="input-dropdown animate__animated animate__fadeIn"
                                v-if="searchedCounterparties.length > 0"
                              >
                                <template #button-content class="p-1">
                                  <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                  />
                                </template>

                                <b-dropdown-item
                                  @click="seeFilteredCounterpartyModal"
                                >
                                  <feather-icon icon="EyeIcon" />
                                  <span class="align-middle ml-50">{{
                                    $t("general_text.see_verified_clients")
                                  }}</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty account -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.cpty_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.cpty_account')"
                            rules="required"
                          >
                            <b-form-input
                              id="cptyAccount"
                              ref="cptyAccount"
                              v-model="tradeForm.cptyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('swap_future.trade_form.cpty_account')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Order Details***************************************************************** */ -->
                  <b-card :title="$t('swap_future.titles.order_details')">
                    <b-row>
                      <!-- order date-->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.order_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.order_date')"
                            rules="required"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('swap_future.trade_form.order_date')
                              "
                              v-model="tradeForm.orderDate"
                              onClose="testClose"
                              class="form-control "
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="checkOrdersDate('Order Date', true)"
                              ref="orderDate"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order time-->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.order_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.order_time')"
                            rules="required|min:8"
                          >
                            <cleave
                              id="orderTime"
                              ref="orderTime"
                              v-model="tradeForm.orderTime"
                              class="form-control "
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('swap_future.trade_form.order_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="checkTime('Order Time')"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration date -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.order_duration_date')
                          "
                          class="mobile-label"
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.order_duration_date')
                            "
                            :rules="
                              tradeForm.timeInForce == 'GTD' ? 'required' : ''
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t('swap_future.trade_form.order_duration_date')
                              "
                              v-model="tradeForm.orderDurationDate"
                              onClose="testClose"
                              class="form-control"
                              ref="orderDurationDate"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="orderDurationDataDisabled"
                              @on-change="
                                checkOrdersDate('Order Duration Date', true)
                              "
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration time -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.order_duration_time')
                          "
                          class="mobile-label"
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.order_duration_time')
                            "
                            :rules="
                              tradeForm.timeInForce == 'GTD' ? 'required' : ''
                            "
                          >
                            <cleave
                              id="orderDurationTime"
                              v-model="tradeForm.orderDurationTime"
                              class="form-control"
                              ref="orderDurationTime"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('swap_future.trade_form.order_duration_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="
                                checkOrdersTime('Order Duration Time')
                              "
                              :disabled="orderDurationTimeDisabled"
                            />

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- entry type -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.entry_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.entry_type')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.entryType"
                              ref="entryType"
                              :options="optEntryTypes"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.select_entry_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- buy/sell -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.buy_sell')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.buy_sell')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.buySell"
                              :options="optbuySells"
                              ref="buySell"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.select_buy_sell')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedBuySell(tradeForm.buySell)
                              "
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order type-->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.order_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.order_type')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.orderType"
                              :options="optOrderTypes"
                              ref="orderType"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.select_order_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- time in force-->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.time_in_force')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.time_in_force')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.timeInForce"
                              :options="optTimeInForces"
                              :clearable="false"
                              ref="timeinForce"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_time_in_force'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="changeTimeInForceType"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cost center -->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.cost_center')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.cost_center')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.costCenter"
                              :options="optCostCenters"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.cost_center')
                              "
                              :reduce="(val) => val.CostCenter"
                              label="CostCenter"
                              ref="CostCenter"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Trade Details***************************************************************** */ -->
                  <b-card :title="$t('swap_future.titles.trade_details')">
                    <b-row>
                      <!-- Trade Date -->
                      <b-col cols="6" xl="4" lg="4" md="5" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.trade_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.trade_date')"
                            rules="required"
                          >
                            <b-input-group
                              :class="
                                tradeForm.tradeDateName == 'Sunday' ||
                                tradeForm.tradeDateName == 'Saturday'
                                  ? 'tradeDateName'
                                  : ''
                              "
                              :append="tradeForm.tradeDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('swap_future.trade_form.trade_date')
                                "
                                v-model="tradeForm.tradeDate"
                                ref="tradeDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="checkTraSettlCptyDate('Trade Date')"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- T+ -->
                      <b-col
                        cols="6"
                        xl="1"
                        lg="1"
                        md="2"
                        sm="6"
                        style="padding-left: 0 !important; padding-right:0.5rem !important"
                      >
                        <b-form-group label="T+">
                          <validation-provider
                            #default="{ errors }"
                            name="T+"
                            rules="min_value:0"
                          >
                            <b-form-input
                              id="T+"
                              v-model.number="tradeForm.Tplus"
                              name="T+"
                              placeholder="T+"
                              @change="arrangeTPlus"
                              type="number"
                              :disabled="disabledTplus"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Settlement Date -->
                      <b-col
                        cols="6"
                        xl="4"
                        lg="4"
                        md="5"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.settlement_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.settlement_date')"
                          >
                            <b-input-group
                              :class="
                                tradeForm.settlementDateName == 'Sunday' ||
                                tradeForm.settlementDateName == 'Saturday'
                                  ? 'settlementDateName'
                                  : ''
                              "
                              :append="tradeForm.settlementDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('swap_future.trade_form.settlement_date')
                                "
                                v-model="tradeForm.settlementDate"
                                ref="settDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="
                                  checkTraSettlCptyDate('Settlement Date')
                                "
                                :disabled="disabledSettlementDate"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- execution time -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.execution_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.execution_time')"
                            rules="min:8"
                          >
                            <cleave
                              id="executionTime"
                              ref="execTime"
                              v-model="tradeForm.executionTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('swap_future.trade_form.execution_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="checkTime('Execution Time')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counter party trader -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.counterparty_trader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.counterparty_trader')
                            "
                          >
                            <v-select
                              v-model="tradeForm.counterpartyTrader"
                              ref="cptyTrader"
                              :options="optCounterPartyTraders"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_counterparty_trader'
                                )
                              "
                              :reduce="(val) => val"
                              class="compact_vue-select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- sfc user -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.sfc_user')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.sfc_user')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.scfUser"
                              ref="tcUser"
                              :options="optScfUsers"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.select_sfc_user')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken via -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.order_taken_via')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.order_taken_via')"
                          >
                            <v-select
                              v-model="tradeForm.orderTakenVia"
                              ref="orderTakenVia"
                              :options="optOrderTakenVias"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_order_taken_via'
                                )
                              "
                              :reduce="(val) => val"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken through -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.order_given_through')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.order_given_through')
                            "
                          >
                            <v-select
                              v-model="tradeForm.orderGivenThrough"
                              ref="orderGivenThrough"
                              :options="optOrderGivenThroughs"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_order_given_through'
                                )
                              "
                              :reduce="(val) => val"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- acting capacity -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.acting_capacity')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.acting_capacity')"
                          >
                            <v-select
                              v-model="tradeForm.actingCapaticy"
                              ref="actingCap"
                              :options="optActingCapacities"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_acting_capacity'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Hedge/Spec -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.hedgespec')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.hedgespec')"
                          >
                            <v-select
                              v-model="tradeForm.hedgeSpec"
                              ref="hedgeSpec"
                              :options="optHedgeSpecs"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.select_hedgespec')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedHedgeSpec(tradeForm.hedgeSpec)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- settlement type-->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.settlement_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.settlement_type')"
                          >
                            <v-select
                              v-model="tradeForm.settlementType"
                              ref="settlementType"
                              :options="optSettlementTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_settlement_type'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedSettlementType(tradeForm.settlementType)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Spot Ref -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.spot_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="spot_ref"
                            rules="'min_value:0"
                          >
                            <cleave
                              id="spot_ref"
                              ref="spotRef"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.spotRef"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t('swap_future.trade_form.spot_ref')
                              "
                              @blur.native="changeSpotRef(tradeForm.spotRef)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- note -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.note')"
                          >
                            <b-form-textarea
                              id="form-textarea"
                              :placeholder="$t('swap_future.trade_form.note')"
                              no-resize
                              v-model="tradeForm.note"
                              ref="note"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>

                <!-- right side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pr-0"
                  style="padding-left:0.5rem!important"
                >
                  <!-- /*******************************Contract Details***************************************************************** */ -->
                  <b-card :title="$t('swap_future.titles.contract_details')">
                    <b-row>
                      <!-- Contract name -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.contract_name')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'swap_future.trade_form.validation_contract_name'
                              )
                            "
                            rules="required"
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                ref="contractName"
                                v-model="tradeForm.contractName"
                                :options="optContractNames"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'swap_future.trade_form.select_contract_name'
                                  )
                                "
                                :reduce="(val) => val"
                                :taggable="true"
                                :loading="contractNameSelectBoxLoading"
                                :state="errors.length > 0 ? false : null"
                                @option:selected="
                                  selectedContractName(tradeForm.contractName)
                                "
                                @option:created="createNewContractName"
                                style="width:100%"
                              />
                              <!-- <i class="fa-solid fa-circle-info fa-xs input-dropdown animate__animated animate__fadeIn"
                          style="padding-left:0.2rem"
                          v-b-tooltip.hover.top="'After entering the contract name you want to add, press enter.'"
                          ></i> -->
                            </div>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Number of Contracts -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.of_contracts')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.of_contracts')"
                            rules="min_value:0"
                          >
                            <b-form-input
                              id="numberOfContracts"
                              v-model.number="tradeForm.numberOfContracts"
                              name="numberOfContracts"
                              :placeholder="
                                $t('swap_future.trade_form.of_contracts')
                              "
                              @change="
                                changedNumberOfContracts(
                                  tradeForm.numberOfContracts
                                )
                              "
                              type="number"
                              ref="numberOfContracts"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                    

                      <!-- client price -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.client_price')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.client_price')"
                            rules="required"
                          >
                            <cleave
                              ref="clientPrice"
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientPrice"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('swap_future.trade_form.client_price')
                              "
                              @blur.native="
                                calculateClientPrice(tradeForm.clientPrice)
                              "
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- contract currency type ccy -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.contract_currency')
                          "
                          rules="required"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.contract_currency')
                            "
                          >
                            <v-select
                              ref="contractCcy"
                              v-model="tradeForm.contractCcy"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.contract_currency')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedContractCcy(tradeForm.contractCcy)
                              "
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                        <!-- Contract Size -->
                        <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.contract_size')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.contract_size')"
                            rules="min_value:0"
                          >
                            <cleave
                              ref="contractSize"
                              id="contractSize"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.contractSize"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('swap_future.trade_form.contract_size')
                              "
                              @blur.native="
                                calculateContractSize(tradeForm.contractSize)
                              "
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- execution price -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6"  
                        >
                        <b-form-group
                          :label="$t('swap_future.trade_form.execution_price')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.execution_price')"
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-input-group
                              ref="groupAppend"
                              :append="tradeForm.executionPriceCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                ref="executionPrice"
                                id="executionPrice"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.executionPrice"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.execution_price')
                                "
                                @blur.native="
                                  calculateExecutionPrice(
                                    tradeForm.executionPrice
                                  )
                                "
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- exchange fee -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.exchange_fee')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.exchange_fee')"
                          >
                            <b-input-group
                              :append="tradeForm.exchangeFeeCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                ref="exchangeFee"
                                id="exchangeFee"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.exchangeFee"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.exchange_fee')
                                "
                                @blur.native="
                                  calculateExchangeFee(tradeForm.exchangeFee)
                                "
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- expiry Date -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.expiry_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.expiry_date')"
                          >
                            <b-input-group
                              :class="
                                tradeForm.expiryDateName == 'Sunday' ||
                                tradeForm.expiryDateName == 'Saturday'
                                  ? 'expiryDateName'
                                  : ''
                              "
                              :append="tradeForm.expiryDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('swap_future.trade_form.expiry_date')
                                "
                                v-model="tradeForm.expiryDate"
                                onClose="testClose"
                                ref="expiryDate"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="
                                  changedExpiryDate(tradeForm.expiryDate)
                                "
                                :disabled="disabledExpiryDate"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- exchange traded & exchange type -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.exchange_traded')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.exchange_traded')"
                            rules="required"
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                v-model="tradeForm.exchangeTraded"
                                ref="exchangeTraded"
                                :options="optExchangeTrades"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'swap_future.trade_form.select_exchange_traded'
                                  )
                                "
                                :reduce="(val) => val"
                                :taggable="false"
                                :loading="exchangeSelectBoxLoading"
                                @option:selected="
                                  selectedExchangeTraded(
                                    tradeForm.exchangeTraded
                                  )
                                "
                                style="width:100%"
                              />
                            </div>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Collateral Per Contract -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.coll_per_contract')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.coll_per_contract')
                            "
                          >
                            <b-input-group
                              :append="tradeForm.collPerContractCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="collPerContract"
                                ref="collPerContract"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.collPerContract"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.coll_per_contract')
                                "
                                @blur.native="
                                  calculateCollPerContract(
                                    tradeForm.collPerContract
                                  )
                                "
                                :disabled="disabledCollPerContract"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- total collateral -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.total_colleteral')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.total_colleteral')
                            "
                          >
                            <b-input-group
                              :append="tradeForm.collTotalCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="collTotal"
                                ref="collTotal"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.collTotal"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.total_colleteral')
                                "
                                @blur.native="
                                  calculateCollTotal(tradeForm.collTotal)
                                "
                                :disabled="disabledCollTotal"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Ticker -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.ticker')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.ticker')"
                          >
                            <b-form-input
                              id="ticker"
                              ref="ticker"
                              v-model="tradeForm.ticker"
                              name="ticker"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('swap_future.trade_form.ticker')"
                              :disabled="disabledTicker"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Comm Per Contract -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.client_per_contract')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.client_per_contract')
                            "
                          >
                            <b-input-group
                              :append="
                                tradeForm.clientCommPerContractCcy != null
                                  ? tradeForm.clientCommPerContractCcy + '/Bps'
                                  : 'Bps'
                              "
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="clientCommPerContract"
                                ref="clientCommPerContract"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientCommPerContract"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'swap_future.trade_form.client_per_contract'
                                  )
                                "
                                @blur.native="
                                  calculateClientCommPerContract(
                                    tradeForm.clientCommPerContract
                                  )
                                "
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Comm Total -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.total_client_comm')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.total_client_comm')
                            "
                          >
                            <b-input-group
                              :append="tradeForm.clientCommTotalCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="clientCommTotal"
                                ref="clientCommTotal"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientCommTotal"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.total_client_comm')
                                "
                                @blur.native="
                                  calculateClientCommTotal(
                                    tradeForm.clientCommTotal
                                  )
                                "
                                :disabled="disabledClientCommTotal"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Cpty Comm Per Contract -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.cpty_per_contract')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.cpty_per_contract')
                            "
                          >
                            <b-input-group
                              :append="
                                tradeForm.cptyCommPerContractCcy != null
                                  ? tradeForm.cptyCommPerContractCcy + '/Bps'
                                  : 'Bps'
                              "
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="cptyCommPerContract"
                                ref="cptyCommPerContract"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.cptyCommPerContract"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.cpty_per_contract')
                                "
                                @blur.native="
                                  calculateCptyCommPerContract(
                                    tradeForm.cptyCommPerContract
                                  )
                                "
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Cpty Comm Total -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.total_cpty_comm')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.total_cpty_comm')"
                          >
                            <b-input-group
                              :append="tradeForm.cptyCommTotalCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="cptyCommTotal"
                                ref="cptyCommTotal"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.cptyCommTotal"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.total_cpty_comm')
                                "
                                @blur.native="
                                  calculateCptyCommTotal(
                                    tradeForm.cptyCommTotal
                                  )
                                "
                                :disabled="disabledCptyCommTotal"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Net Comission -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.net_comm')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.net_comm')"
                          >
                            <b-input-group
                              :append="tradeForm.netCommissionCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="netCommission"
                                ref="netCommission"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.netCommission"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.net_comm')
                                "
                                @blur.native="
                                  calculateNetCommission(
                                    tradeForm.netCommission
                                  )
                                "
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Spread Gain -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.spead_gain')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.spead_gain')"
                          >
                            <b-input-group
                              :append="tradeForm.spreadGainCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="spreadGain"
                                ref="spreadGain"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.spreadGain"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.spead_gain')
                                "
                                @blur.native="
                                  calculateSpreadGain(tradeForm.spreadGain)
                                "
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Total Tc Profit -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.total_tc_profit')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.total_tc_profit')"
                          >
                            <b-input-group
                              :append="tradeForm.totalTcProfitCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="totalTcProfit"
                                ref="totalTcProfit"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.totalTcProfit"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.total_tc_profit')
                                "
                                @blur.native="
                                  calculateTotalTcProfit(
                                    tradeForm.totalTcProfit
                                  )
                                "
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Notice date -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.notice_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.notice_date')"
                          >
                          <b-input-group
                              :class="
                                tradeForm.noticeDateName == 'Sunday' ||
                                tradeForm.noticeDateName == 'Saturday'
                                  ? 'noticeDateName'
                                  : ''
                              "
                              :append="tradeForm.noticeDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('swap_future.trade_form.notice_date')
                                "
                                v-model="tradeForm.noticeDate"
                                onClose="testClose"
                                ref="noticeDate"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="
                                  checkTraSettlCptyDate('Notice Date')
                                "
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                  <!-- /******************************* Settlement Details***************************************************************** */ -->

                  <b-card :title="$t('swap_future.titles.settlement_details')">
                    <b-row>
                      <!-- Cpty Confo Date -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.ConfirmationDate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.ConfirmationDate')
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t('swap_future.trade_form.ConfirmationDate')
                              "
                              v-model="tradeForm.confirmationDate"
                              onClose="testClose"
                              ref="confirmationDate"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="
                                selectedConfirmationDate(
                                  tradeForm.confirmationDate
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Time -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.ConfirmationTime')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.ConfirmationTime')
                            "
                          >
                            <cleave
                              id="confirmationTime"
                              ref="confirmationTime"
                              v-model="tradeForm.confirmationTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('swap_future.trade_form.ConfirmationTime')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="
                                changeConfirmationTime(
                                  tradeForm.confirmationTime
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Trader -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.ConfirmationTrader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.ConfirmationTrader')
                            "
                          >
                            <v-select
                              v-model="tradeForm.confirmationTrader"
                              ref="confirmationTrader"
                              :options="optCounterParties"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.ConfirmationTrader')
                              "
                              :reduce="(val) => val"
                              :filterable="true"
                              @option:selected="
                                selectedConfirmationTrader(
                                  tradeForm.confirmationTrader
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Client Confo Date -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.ConfirmationDateClient')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'swap_future.trade_form.ConfirmationDateClient'
                              )
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.ConfirmationDateClient'
                                )
                              "
                              v-model="tradeForm.confirmationDateClient"
                              ref="confirmationDateClient"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="
                                selectedConfirmationDateClient(
                                  tradeForm.confirmationDateClient
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Time -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.ConfirmationTimeClient')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'swap_future.trade_form.ConfirmationTimeClient'
                              )
                            "
                          >
                            <cleave
                              id="confirmationTimeClient"
                              ref="confirmationTimeClient"
                              v-model="tradeForm.confirmationTimeClient"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.ConfirmationTimeClient'
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="
                                changeConfirmationTimeClient(
                                  tradeForm.confirmationTimeClient
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty MTM Ref  -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.CpRef')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.CpRef')"
                          >
                            <b-form-input
                              id="counterpartyRef"
                              ref="CpRef"
                              v-model="tradeForm.counterpartyRef"
                              name="counterpartyRef"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('swap_future.trade_form.CpRef')"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty UTI  -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.CptyUti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.CptyUti')"
                          >
                            <b-form-input
                              id="cptyUti"
                              ref="cptyUti"
                              v-model="tradeForm.cptyUti"
                              name="cptyUti"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('swap_future.trade_form.CptyUti')
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Tc Uti  -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.TcUti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.TcUti')"
                          >
                            <b-form-input
                              id="tcUti"
                              ref="tcUti"
                              v-model="tradeForm.tcUti"
                              name="tcUti"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('swap_future.trade_form.TcUti')"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Unique Link ID -->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.UniqueLinkId')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.UniqueLinkId')"
                          >
                            <b-form-input
                              id="uniqueLinkID"
                              ref="uniqueLinkId"
                              v-model.number="tradeForm.uniqueLinkID"
                              type="number"
                              name="uniqueLinkID"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('swap_future.trade_form.UniqueLinkId')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- ISIN -->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.isin')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="ISIN"
                          >
                            <b-form-input
                              id="isin"
                              ref="isin"
                              v-model="tradeForm.isin"
                              :name="$t('swap_future.trade_form.isin')"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('swap_future.trade_form.isin')"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- trade ref -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.trade_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.trade_ref')"
                          >
                            <b-input-group
                              :prepend="$t('swap_future.trade_form.trade_ref')"
                            >
                              <b-form-input
                                style="background: aliceblue !important;"
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('swap_future.trade_form.trade_ref')
                                "
                                :disabled="true"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" /><b>{{
                $t("swap_future.overlay_messages.amendment_message")
              }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="d-flex align-items-center" style="flex: 1;">
          <!-- trade actions -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pl-0 d-flex justify-content-between align-items-center"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="actionsData == null || actionsData.length == 0"
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">{{
                $t("swap_future.buttons.trade_actions")
              }}</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="click"
              id="actions-popover"
            >
              <template #title>
                {{ $t("swap_future.titles.trade_actions") }}
              </template>
              <b-table-simple hover small caption-top responsive bordered>
                <b-tbody class="compact_form_actions-table">
                  <b-tr v-for="(action, index) in actionsData" :key="index">
                    <td style="width: 20%;">
                      {{ action.type }}
                    </td>
                    <td style="width: 20%;">
                      {{ action.user }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.date }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.reason }}
                    </td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-popover>
          </b-col>
          <!-- trade ok operation buttons -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pr-0 d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="float-right mr-1"
              @click="resetTradeModal"
            >
              {{ $t("swap_future.modals.modal_close") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="float-right mr-1"
              @click="tradeOkOperation"
            >
              {{ $t("swap_future.modals.amend_trade_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      {{/****END*** amend for trades modal*/}}
    </b-modal>

    {{/** view filtered client modal*/}}
    <b-modal
      id="clientModal"
      v-model="viewFilteredClientModal"
      :title="$t('client_table_modal.filtered_clients')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetClientModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedClients"
                selectable
                select-mode="single"
                @row-clicked="selectedClientFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="
                  $t('swap_future.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered modal*/}}
    </b-modal>
    {{/** view filtered counterparty modal*/}}
    <b-modal
      id="counterpartyModal"
      v-model="viewFilteredCounterpartyModal"
      :title="$t('client_table_modal.filtered_counterparties')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetCounterpartyModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedCounterparties"
                selectable
                select-mode="single"
                @row-clicked="selectedCounterpartyFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="
                  $t('swap_future.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered counterparty modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import swapFutureStoreModule from "./swapFutureStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  alphaNum,
  email,
  max,
  max_value,
  min_value,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
    AppTimeline,
    AppTimelineItem,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const SWAPFUTURE_APP_STORE_MODULE_NAME = "swapFuture";
    // Register module
    if (!store.hasModule(SWAPFUTURE_APP_STORE_MODULE_NAME))
      store.registerModule(
        SWAPFUTURE_APP_STORE_MODULE_NAME,
        swapFutureStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SWAPFUTURE_APP_STORE_MODULE_NAME))
        store.unregisterModule(SWAPFUTURE_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},

  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data() {
    return {
      amendTradeModal: false,
      tradeModalLoading: false,
      loadingSecurityDesc: false,
      loadingClient: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      orderDurationInputsVisible: true,
      exchangeSelectBoxLoading: false,
      defaultEntryName: null,
      loadingDefaultEntry: false,
      contractNameSelectBoxLoading: false,
      checkVerify: false,
      amendmentReason: null,
      optDefaultEntries: [],
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optbuySells: ["Buy", "Sell"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optEntryTypes: ["Order", "Execution"],
      optContractNames: [],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optPriceTypes: [],
      optExchangeTrades: [],
      optScfUsers: [],
      optActingCapacities: ["PRINCIPAL", "AGENT","MATCHED PRINCIPAL"],
      optHedgeSpecs: ["HEDGE", "SPECULATION"],
      optSettlementTypes: ["PHYSICAL", "CASH"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      optCounterPartyTraders: [],
      fetchedTradeData: {},
      oldTradeFormData: {},
      actionsData: [],

      disabledExpiryDate: false,
      disabledSettlementDate: false,
      disabledTplus: false,
      disabledContractSize: false,
      disabledExchangeTraded: false,
      disabledTicker: false,
      disabledCollPerContract: false,
      disabledCollTotal: false,
      disabledClientCommTotal: false,
      disabledCptyCommTotal: false,
      orderDurationDataDisabled: true,
      orderDurationTimeDisabled: true,
      disabledCcyInputs: true,
      getContractFieldData:null,
      oldContractFieldName:null,
      tradeForm: {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        //Contract details
        contractName: null,
        numberOfContracts: null,
        contractSize: null,
        clientPrice: null,
        contractCcy: null,
        executionPrice: null,
        executionPriceCcy: null,
        exchangeFee: null,
        exchangeFeeCcy: null,
        expiryDate: null,
        expiryDateName: null,
        exchangeTraded: null,
        ticker: null,
        collPerContract: null,
        collPerContractCcy: null,
        collTotal: null,
        collTotalCcy: null,
        clientCommPerContract: null,
        clientCommPerContractCcy: null,
        clientCommTotal: null,
        clientCommTotalCcy: null,
        cptyCommPerContract: null,
        cptyCommPerContractCcy: null,
        cptyCommTotal: null,
        cptyCommTotalCcy: null,
        netCommission: null,
        netCommissionCcy: null,
        spreadGain: null,
        spreadGainCcy: null,
        totalTcProfit: null,
        totalTcProfitCcy: null,
        //order details
        orderDurationDate: moment().format("DD-MM-YYYY "),
        orderDurationDateName: null,
        orderDurationTime: moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString(),
        entryType: "Order",
        buySell: "Buy",
        orderType: "Limit",
        timeInForce: "DAY",
        orderDate: moment().format("DD-MM-YYYY"),
        orderDateName: null,
        orderTime: moment().format("HH:mm:ss"),
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        noticeDate:null,
        noticeDateName: null,
        Tplus: null,
        executionTime: null,
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: "PRINCIPAL",
        hedgeSpec: "HEDGE",
        settlementType: "CASH",
        spotRef: null,
        note: null,
        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        counterpartyRef: null,
        isin: null,
        tradeRef: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          // novos
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
          // fim novos
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 4,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },

      cleave6DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 6,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },
      cleaveQuantityOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 0,
          numeralIntegerScale: 0,
        },
      },
      viewFilteredClientModal: false,
      viewFilteredCounterpartyModal: false,
      clientTableColumns: [
        {
          key: "AccountName",
          label: this.$t("client_table_modal.account_name"),
          class: "text-center",
        },
        {
          key: "AccountNumber",
          label: this.$t("client_table_modal.account_code"),
          sortable: false,
          class: "text-center",
        },
        {
          key: "SettlementType",
          label: this.$t("client_table_modal.settlement_type"),
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchSwapFuture"]),
    getArangeSwapFutureTCUsers() {
      store
        .dispatch("swapFuture/fetchArangeSwapFutureTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
            this.optPriceTypes = res.data.currencies;
          } else if (res.data.info == "no_users") {
            this.errorMessage(
              this.$t("swap_future.messages.user_error_no_users")
            );
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllExchanges() {
      store
        .dispatch("swapFuture/getAllExchanges")
        .then((res) => {
          this.optExchangeTrades = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFutureContractSpecs() {
      store
        .dispatch("swapFuture/getFutureContractSpecs")
        .then((res) => {
          this.optContractNames = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeSwapFutureOrderTakenGivenThrough() {
      store
        .dispatch(
          "swapFuture/fetchArrangeSwapFutureOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(
              this.$t("swap_future.messages.not_found_given_order_data")
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeSwapFutureCurrencies() {
      store
        .dispatch("swapFuture/fetchArrangeSwapFutureCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getObjectDiff(obj1, obj2) {
      const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
          result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key);
          result.splice(resultKeyIndex, 1);
        }
        return result;
      }, Object.keys(obj2));
      return diff;
    },
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      const self = this;
      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          this.$checkAccessRight("SwapFuture", "Amend").then((res) => {
            if (res.data.valid) {
              if (
                this.getObjectDiff(this.oldTradeFormData, this.tradeForm)
                  .length > 0
              ) {
                this.tradeForm.uti = this.tradeForm.tcUti;

                store
                  .dispatch("swapFuture/checkTradeVerifyOperation", {
                    auth: this.user,
                    trade: this.tradeForm,
                  })
                  .then((res) => {
                    if (res.data.info == "verified") {
                      self.tradeModalLoading = true;
                      this.$swal({
                        title: self.$t("swap_future.titles.reverified_trade"),
                        text: self.$t(
                          "swap_future.messages.entry_already_verified"
                        ),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: self.$t(
                          "swap_future.reason.continue"
                        ),
                        cancelButtonText: self.$t("swap_future.reason.Cancel"),
                        customClass: {
                          confirmButton: "btn btn-success mr-1",
                          cancelButton: "btn btn-danger",
                          denyButton: "btn btn-primary",
                        },
                        showClass: {
                          popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                          popup: "animate__animated animate__fadeOutUp",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          store
                            .dispatch("swapFuture/removeTradeVerifyOperation", {
                              auth: self.user,
                              tcUti: self.tradeForm.tcUti,
                            })
                            .then((res) => {
                              if (res.data.info == "Pass") {
                                self.tradeEnterReasonText().then((res) => {
                                  if (res) {
                                    self.tradeModalLoading = true;

                                    //set and check trade data
                                    let tradeData = self.setTradeData();

                                    store
                                      .dispatch(
                                        "swapFuture/tradeAmendmentUpdate",
                                        {
                                          auth: self.user,
                                          oldTradeData: self.oldTradeFormData,
                                          tradeData: tradeData,
                                        }
                                      )
                                      .then((res) => {
                                        if (res.data.info == "Pass") {
                                          self.tradeModalLoading = false;
                                          self.resetTradeModal();
                                          this.tradeUpdatedSuccessMessage();
                                          this.$emit("updatedData", true);

                                          self.amendTradeModal = false;
                                        } else {
                                          self.errorMessage(res.data.message);
                                          self.tradeModalLoading = false;
                                        }
                                      })
                                      .catch((error) => {
                                        console.log(error);
                                      });
                                  } else {
                                    self.tradeModalLoading = false;
                                  }
                                });
                              } else {
                                self.errorMessage(res.data.message);
                              }
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        } else {
                          self.amendTradeModal = true;
                          self.tradeModalLoading = false;
                          self.exitAmendOperationMessage();
                        }
                      });
                    } else {
                      self.tradeModalLoading = true;
                      self.tradeEnterReasonText().then((res) => {
                        if (res) {
                          //set and check trade data
                          let tradeData = self.setTradeData();

                          store
                            .dispatch("swapFuture/tradeAmendmentUpdate", {
                              auth: self.user,
                              oldTradeData: self.oldTradeFormData,
                              tradeData: tradeData,
                            })
                            .then((res) => {
                              if (res.data.info == "Pass") {
                                self.tradeModalLoading = false;
                                self.resetTradeModal();
                                this.tradeUpdatedSuccessMessage();
                                this.$emit("updatedData", true);

                                self.amendTradeModal = false;
                              } else {
                                self.errorMessage(res.data.message);
                                self.tradeModalLoading = false;
                              }
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        } else {
                          self.tradeModalLoading = false;
                        }
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                this.noChangesMessage();
              }
            } else {
              this.amendTradeModal = false;
              this.notAuthToastMessage();
            }
          });
        } else {
          let notEnteredDatas = "";

          let checkRequiredDatas = _.map(
            this.$refs.tradeValidation.errors,
            (value, key) => ({ key, value })
          );

          if (checkRequiredDatas.length > 0) {
            let first = true;
            checkRequiredDatas.forEach((r) => {
              if (r.value.length > 0) {
                if (first) {
                  notEnteredDatas += r.key;
                  first = false;
                } else {
                  notEnteredDatas += "," + r.key;
                  first = false;
                }
              }
            });
            this.checkRequiredInputsMessage(notEnteredDatas, 20000);
          }
        }
      });
    },
    //********************************************** client && counterpary section ***********************/
    selectedClient(search) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;
      this.tradeForm.clientCommPerContract =
        selecting.ClientCommPerContract != null
          ? parseFloat(selecting.ClientCommPerContract)
          : null;

      if (this.tradeForm.clientCommPerContract != null) {
        this.tradeForm.clientCommPerContract = Number.parseFloat(
          this.tradeForm.clientCommPerContract
        ).toFixed(2);
      }
    },

    selectedClientFromTable(client) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === client.AccountName
      );
      this.tradeForm.client = client.AccountName;
      this.tradeForm.clientAccount = client.AccountNumber;
      this.tradeForm.clientCommPerContract =
        selecting.ClientCommPerContract != null
          ? parseFloat(selecting.ClientCommPerContract)
          : null;

      if (this.tradeForm.clientCommPerContract != null) {
        this.tradeForm.clientCommPerContract = Number.parseFloat(
          this.tradeForm.clientCommPerContract
        ).toFixed(2);
      }
      this.viewFilteredClientModal = false;
    },
    seeFilteredClientModal() {
      this.viewFilteredClientModal = true;
    },
    resetClientModal() {
      this.viewFilteredClientModal = false;
    },
    selectedCounterparty(search) {
      //this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;
      this.optCounterPartyTraders = [];

      let selecting = this.searchedCounterparties.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.cptyAccount = selecting.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
              let listTraders = [];
              res.data.resTrader.forEach((element) => {
                if (!listTraders.includes(element.Trader)) {
                  listTraders.push(element.Trader);
                }
              });
              this.optCounterPartyTraders = listTraders;
            }
          })
          .catch((error) => reject(error));
      });
    },
    selectedCounterpartyFromTable(cpty) {
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      this.tradeForm.counterparty = cpty.AccountName;
      this.tradeForm.cptyAccount = cpty.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: cpty.AccountName,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
            }
            this.viewFilteredCounterpartyModal = false;
          })
          .catch((error) => reject(error));
      });
    },
    seeFilteredCounterpartyModal() {
      this.viewFilteredCounterpartyModal = true;
    },
    resetCounterpartyModal() {
      this.viewFilteredCounterpartyModal = false;
    },

    //********************************************** END client && counterpary section ******************/

    //********************************************** order details ***********************/
    checkOrdersDate(type, defDateName = false) {
      // console.log(this.tradeForm.orderDurationDate)
      // console.log(new Date(Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate)) + 48 * 60 * 60 * 1000))

      if (type == "Order Date") {
        if (defDateName) {
          this.tradeForm.orderDateName = null;
        }
        if (this.tradeForm.timeInForce == "DAY") {
          this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.order_date_after_order_duration_date")
          );
          this.tradeForm.orderDate = null;
        }
      } else if (type == "Order Duration Date") {
        if (defDateName) {
          this.tradeForm.orderDurationDateName = null;
        }
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.order_duration_less_order_date")
          );
          this.tradeForm.orderDurationDate = null;
        }
      }
    },
    changeTimeInForceType() {
      if (this.tradeForm.timeInForce == "DAY") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = true;
        this.orderDurationTimeDisabled = true;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "GTC") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "GTD") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = false;
        this.orderDurationTimeDisabled = false;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "FOK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "FAK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }
    },
    checkTraSettlCptyDate(type, defDateName = false) {
      if (type == "Trade Date") {
        if (defDateName) {
          this.defTradeDateName = null;
        }
        let tradeDate = new Date(this.MDYdateFormat(this.tradeForm.tradeDate));

        //set trade date name
        this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

        //arrange as T+
        if (this.tradeForm.Tplus > 0) {
          this.arrangeTPlus();
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("swap_future.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.tradeDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.expiryDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("swap_future.messages.trade_date_before_expiry_date")
          );
          this.tradeForm.tradeDate = null;
        }
      } else if (type == "Settlement Date") {
        let settleDate = new Date(
          this.MDYdateFormat(this.tradeForm.settlementDate)
        );

        //set settle date name
        this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("swap_future.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.settlementDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.expiryDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("swap_future.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.settlementDate = null;
        }
      } else if(type == "Notice Date"){
        let noticeDate = new Date(this.MDYdateFormat(this.tradeForm.noticeDate));

        //set notice date name
        this.tradeForm.noticeDateName = this.weekday[noticeDate.getDay()];

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.noticeDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("swap_future.messages.trade_date_after_notice_date")
          );
          this.tradeForm.noticeDate = null;
          this.$refs.noticeDate.fp.clear();
        }
      }
    },

    getHolidays() {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, this.user)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => reject(error));
      });
    },

    checkHolidaysDate(holidays, tradeDate) {
      let checkDate = tradeDate;
      let status = true;
      holidays.forEach((h) => {
        if (this.dateFormat(h.Date) == this.dateFormat(tradeDate)) {
          checkDate.setDate(checkDate.getDate() + 1);
          status = false;
          this.checkHolidaysDate(holidays, checkDate);
        }
      });
      if (status) {
        this.checkedHolidayDate = checkDate;
      }
    },

    arrangeTPlus() {
      if (
        this.tradeForm.tradeDate != null &&
        this.tradeForm.tradeDateName != null
      ) {
        if (this.tradeForm.Tplus > 0) {
          this.getHolidays().then((holidays) => {
            if (holidays.length > 0) {
              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.Tplus;
              tradeDate.setDate(tradeDate.getDate() + period);

              this.checkHolidaysDate(holidays, tradeDate);

              //found settlement date name
              let settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 2
                );
              } else if (settlementDateName == "Sunday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 1
                );
              }

              //again check holiday
              this.checkHolidaysDate(holidays, this.checkedHolidayDate);

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(
                this.checkedHolidayDate
              );
              this.tradeForm.settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];
            } else {
              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.Tplus;

              tradeDate.setDate(tradeDate.getDate() + period);

              //found settlement date name
              let settlementDateName = this.weekday[tradeDate.getDay()];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                tradeDate.setDate(tradeDate.getDate() + 2);
              } else if (settlementDateName == "Sunday") {
                tradeDate.setDate(tradeDate.getDate() + 1);
              }

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(tradeDate);
              this.tradeForm.settlementDateName = this.weekday[
                tradeDate.getDay()
              ];
            }
          });
        }
      }
    },

    checkTime(type) {
      if (type == "Order Time") {
        if (
          this.tradeForm.orderTime != null &&
          this.tradeForm.orderTime.length < 8
        ) {
          for (let i = this.tradeForm.orderTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderTime += ":";
            } else {
              this.tradeForm.orderTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t("equity_bond.messages.order_time_later_execution_time")
            );
            this.tradeForm.orderTime = null;
          }
        }

        this.checkOrdersTime("Order Time");
      } else if ("Execution Time") {
        if (
          this.tradeForm.executionTime != null &&
          this.tradeForm.executionTime.length < 8
        ) {
          for (let i = this.tradeForm.executionTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.executionTime += ":";
            } else if (i == 5) {
              this.tradeForm.executionTime += ":";
            } else {
              this.tradeForm.executionTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t("equity_bond.messages.execution_time_later_order_time")
            );
            this.tradeForm.executionTime = null;
          }
        }
      }
    },
    checkOrdersTime(type) {
      if (type == "Order Duration Time") {
        if (
          this.tradeForm.orderDurationTime != null &&
          this.tradeForm.orderDurationTime.length < 8
        ) {
          for (let i = this.tradeForm.orderDurationTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderDurationTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderDurationTime += ":";
            } else {
              this.tradeForm.orderDurationTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t("equity_bond.messages.order_duration_later_order_time")
            );
            this.tradeForm.orderDurationTime = null;
          }
        }
      } else if (type == "Order Time") {
        if (
          this.tradeForm.orderTime != null &&
          this.tradeForm.orderTime.length < 8
        ) {
          for (let i = this.tradeForm.orderTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderTime += ":";
            } else {
              this.tradeForm.orderTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t(
                "equity_bond.messages.order_time_less_order_duration_time"
              )
            );
            this.tradeForm.orderTime = null;
          }
        }
      }
    },
    selectedHedgeSpec(type) {},
    selectedSettlementType(type) {},
    changeSpotRef() {},

    //********************************************** END order details ******************/

    //********************************************** settlement details ***********************/
    selectedConfirmationDate(date) {},
    changeConfirmationTime(time) {
      if (
        this.tradeForm.confirmationTime != null &&
        this.tradeForm.confirmationTime.length < 8
      ) {
        for (let i = this.tradeForm.confirmationTime.length; i < 8; i++) {
          if (i == 2) {
            this.tradeForm.confirmationTime += ":";
          } else if (i == 5) {
            this.tradeForm.confirmationTime += ":";
          } else {
            this.tradeForm.confirmationTime += "0";
          }
        }
      }
    },
    selectedConfirmationTrader(trader) {},
    selectedConfirmationDateClient(date) {},
    changeConfirmationTimeClient(time) {
      if (
        this.tradeForm.confirmationTimeClient != null &&
        this.tradeForm.confirmationTimeClient.length < 8
      ) {
        for (let i = this.tradeForm.confirmationTimeClient.length; i < 8; i++) {
          if (i == 2) {
            this.tradeForm.confirmationTimeClient += ":";
          } else if (i == 5) {
            this.tradeForm.confirmationTimeClient += ":";
          } else {
            this.tradeForm.confirmationTimeClient += "0";
          }
        }
      }
    },

    checkOrdersTime(type) {
      if (type == "Order Time") {
        if (
          this.tradeForm.orderTime != null &&
          this.tradeForm.orderTime.length < 8
        ) {
          for (let i = this.tradeForm.orderTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderTime += ":";
            } else {
              this.tradeForm.orderTime += "0";
            }
          }
        }
      }
    },

    //********************************************** END settlement details ******************/

    //********************************************** Contract details ***********************/
    selectedBuySell(type) {
      this.arrangeCollateralAndComission();
    },

    async selectedContractName(contractName) {
      let futureContractSpecs = await this.getContractField(
        this.tradeForm.contractName,
        "CommissionType"
      );

      if (futureContractSpecs != null) {
        this.tradeForm.contractCcy = futureContractSpecs.Ccy;
        this.tradeForm.contractSize = futureContractSpecs.ContractSize;
        this.tradeForm.collPerContract = futureContractSpecs.CollPerContract;

        this.tradeForm.exchangeTraded = futureContractSpecs.ExchangeTraded;
        this.optExchangeTrades.push("OTC");
        if (futureContractSpecs.ExchangeTraded != null) {
          this.optExchangeTrades.push(futureContractSpecs.ExchangeTraded);
        }

        if (futureContractSpecs.Coll != null) {
          this.tradeForm.collPerContract = futureContractSpecs.Coll;
        }

        this.tradeForm.expiryDate = moment(
          String(futureContractSpecs.ExpiryDate),
          "YYYY-MM-DD"
        ).format("DD-MM-YYYY");
        this.tradeForm.settlementDate = this.tradeForm.tradeDate;
        this.tradeForm.ticker = futureContractSpecs.Ticker;
      }

      this.copyCurrencies();
      this.arrangeCommissionFormat(futureContractSpecs);
      this.arrangeTicker();
      this.arrangeVobAccountCommission(futureContractSpecs);
      this.arrangeCollateralAndCommission();

      this.arrangeInputFormats();
    },

    createNewContractName(name) {
      // this.contractNameSelectBoxLoading = true
      // if(name.length > 3) {
      //   axiosIns
      //     .post(`saveContractName`, {
      //       contractName: name.toUpperCase().trim(),
      //     })
      //     .then((res) => {
      //       if(res.data.info == "success"){
      //         this.optContractNames.push(name);
      //         this.tradeForm.contractName = name;
      //         this.contractNameSelectBoxLoading = false;
      //       } else {
      //         this.contractNameSelectBoxLoading = false;
      //         this.wrongToastMessage(
      //         this.$t("swap_future.messages.already_exist_contractname")
      //       );
      //       }
      //     })
      //     .catch((error) => {
      //       reject(error)
      //       this.contractNameSelectBoxLoading = false
      //     } );
      // }
    },
    async changedNumberOfContracts(value) {
      this.convertNumberInputFormat();

      this.arrangeCollateralAndCommission();
    },

    async calculateContractSize(value) {
      this.arrangeCollateralAndCommission();
    },
    async calculateClientPrice(value) {
      this.arrangeCollateralAndCommission();
      this.tradeForm.executionPrice = this.tradeForm.clientPrice;
      
      this.arrangeInputFormats();
    },
    selectedContractCcy(ccy) {
      this.copyCurrencies();
    },
    calculateExecutionPrice(value) {
      this.arrangeCollateralAndCommission();
    },
    async calculateExchangeFee(value) {
      this.arrangeCollateralAndCommission();
      let getContractFieldData = await this.getContractField(
        this.tradeForm.contractName,
        "CommissionType"
      );

      if (getContractFieldData.resContractSpecs == "Basis") {
        this.tradeForm.executionPrice = this.tradeForm.clientPrice;
      }
      this.arrangeInputFormats();
    },

    changedExpiryDate(date) {
      let expiryDate = new Date(this.MDYdateFormat(this.tradeForm.expiryDate));

      //set trade date name
      this.tradeForm.expiryDateName = this.weekday[expiryDate.getDay()];

      this.arrangeTicker();
    },

    selectedExchangeTraded(trade) {},
    calculateTicker(value) {},
    calculateCollPerContract(value) {
      this.arrangeCollateralAndCommission();
      this.arrangeTicker();
      this.arrangeInputFormats();
    },
    calculateCollTotal(value) {
      this.arrangeInputFormats();
    },
    calculateClientCommPerContract(value) {
      this.arrangeCollateralAndCommission();
      this.arrangeInputFormats();
    },
    calculateClientCommTotal(value) {
      this.arrangeInputFormats();
    },
    calculateCptyCommPerContract(value) {
      this.arrangeCollateralAndCommission();
      this.arrangeInputFormats();
    },
    calculateCptyCommTotal(value) {
      this.arrangeInputFormats();
    },
    calculateNetCommission(value) {
      this.arrangeInputFormats();
    },
    calculateSpreadGain(value) {
      this.arrangeInputFormats();
    },
    calculateTotalTcProfit(value) {
      this.arrangeInputFormats();
    },

    //********************************************** END Contract details ***********************/

    //**********************************************  Calculate func. ***********************/

    arrangeCollateralAndComission() {
      this.convertNumberInputFormat();

      this.arrangeTotalColl();
      this.arrangeTotalClientCommission();
      this.arrangeTotalCptyCommission();
      this.arrangeNetCommission();
      this.arrangeSpreadGain();
      this.arrangeTotalTcProfit();

      this.arrangeInputFormats();
    },

    arrangeTotalColl() {
      if (
        this.tradeForm.contractSize != null &&
        this.tradeForm.collPerContract != null
      ) {
        //this.convertNumberInputFormat();
        this.tradeForm.collTotal =
          this.tradeForm.contractSize * this.tradeForm.numberOfContracts;
        //this.arrangeInputFormats();
      }
    },

    async arrangeTotalClientCommission() {
      const self = this;
      if(this.getContractFieldData != null){
        this.convertNumberInputFormat();
        let getContractFieldData = this.getContractFieldData.specsData;
      

          if (getContractFieldData != null) {
            if (
              self.tradeForm.numberOfContracts != null &&
              self.tradeForm.clientCommPerContract != null
            ) {
              if (getContractFieldData.ComissionType == "Basis") {
                if (
                  self.tradeForm.contractSize != null &&
                  self.tradeForm.clientPrice != null
                ) {
                  self.tradeForm.clientCommTotal =
                    (self.tradeForm.clientCommPerContract *
                      self.tradeForm.numberOfContracts *
                      self.tradeForm.clientPrice *
                      self.tradeForm.contractSize) /
                    10000;
                }
              } else {
                self.tradeForm.clientCommTotal =
                  self.tradeForm.clientCommPerContract *
                  self.tradeForm.numberOfContracts;
              }
            } else if (this.tradeForm.clientCommPerContract == null) {
              this.tradeForm.clientCommTotal = 0;
            }
          }
     
      }
      this.arrangeInputFormats();
      
    },
    async arrangeTotalCptyCommission() {
      const self = this;
      
        if(this.getContractFieldData != null ){
          this.convertNumberInputFormat();
          let getContractFieldData = this.getContractFieldData.specsData;

          if (getContractFieldData.resContractSpecs) {
          if (
            self.tradeForm.numberOfContracts != null &&
            self.tradeForm.cptyCommPerContract != null
          ) {
            if (getContractFieldData.ComissionType == "Basis") {
              if (
                self.tradeForm.contractSize != null &&
                self.tradeForm.executionPrice != null
              ) {
                self.tradeForm.cptyCommTotal =
                  (self.tradeForm.cptyCommPerContract *
                    self.tradeForm.numberOfContracts *
                    self.tradeForm.executionPrice *
                    self.tradeForm.contractSize) /
                  10000;
              }
            } else {
              self.tradeForm.cptyCommTotal =
                self.tradeForm.cptyCommPerContract *
                self.tradeForm.numberOfContracts;
            }
          } else if (this.tradeForm.cptyCommPerContract == null) {
            this.tradeForm.cptyCommTotal = 0;
          }
        }
        
      }
      this.arrangeInputFormats();
     
    },
    arrangeNetCommission() {
      this.convertNumberInputFormat();
      if (
        this.tradeForm.cptyCommTotal != null &&
        this.tradeForm.clientCommTotal != null
      ) {
        this.tradeForm.netCommission =
          this.tradeForm.clientCommTotal - this.tradeForm.cptyCommTotal;
      } else if (this.tradeForm.clientCommTotal != null) {
        this.tradeForm.netCommission = this.tradeForm.clientCommTotal;
      } else if (this.tradeForm.cptyCommTotal != null) {
        this.tradeForm.netCommission = this.tradeForm.cptyCommTotal * -1;
      }
      this.arrangeInputFormats();
    },
    arrangeSpreadGain() {
      this.convertNumberInputFormat();
      if (
        this.tradeForm.buySell != null &&
        this.tradeForm.numberOfContracts != null &&
        this.tradeForm.contractSize != null
      ) {
        if (this.tradeForm.buySell == "Buy") {
          if (
            this.tradeForm.clientPrice != null &&
            this.tradeForm.executionPrice != null
          ) {
            this.tradeForm.spreadGain =
              (this.tradeForm.clientPrice - this.tradeForm.executionPrice) *
              this.tradeForm.numberOfContracts *
              this.tradeForm.contractSize;
          } else {
            this.tradeForm.spreadGain = null;
          }
        } else {
          if (
            this.tradeForm.clientPrice != null &&
            this.tradeForm.executionPrice != null
          ) {
            this.tradeForm.spreadGain =
              (this.tradeForm.executionPrice - this.tradeForm.clientPrice) *
              this.tradeForm.numberOfContracts *
              this.tradeForm.contractSize;
          } else {
            this.tradeForm.spreadGain = null;
          }
        }
      }
      this.arrangeInputFormats();
    },
    arrangeTotalTcProfit() {
      this.convertNumberInputFormat();

      if (this.tradeForm.netCommission != null) {
        if (this.tradeForm.spreadGain != null) {
          this.tradeForm.totalTcProfit =
            this.tradeForm.netCommission + this.tradeForm.spreadGain;
        } else {
          this.tradeForm.totalTcProfit = this.tradeForm.netCommission;
        }
      } else {
        if (this.tradeForm.spreadGain != null) {
          this.tradeForm.totalTcProfit = this.tradeForm.spreadGain;
        }
      }

      this.arrangeInputFormats();
    },

    copyCurrencies() {
      let ccy = JSON.parse(JSON.stringify(this.tradeForm.contractCcy));
      this.tradeForm.executionPriceCcy = ccy;
      this.tradeForm.exchangeFeeCcy = ccy;
      this.tradeForm.collTotalCcy = ccy;
      this.tradeForm.clientCommPerContractCcy = ccy;
      this.tradeForm.clientCommTotalCcy = ccy;
      this.tradeForm.cptyCommPerContractCcy = ccy;
      this.tradeForm.cptyCommTotalCcy = ccy;
      this.tradeForm.netCommissionCcy = ccy;
      this.tradeForm.spreadGainCcy = ccy;
      this.tradeForm.totalTcProfitCcy = ccy;
    },
    arrangeCommissionFormat(futureContractSpecs) {
      if (
        futureContractSpecs != null &&
        futureContractSpecs.Netting == "Manual"
      ) {
        this.disabledExpiryDate = false;
        this.disabledSettlementDate = false;
        this.disabledTplus = false;
        this.disabledContractSize = false;
        this.disabledExchangeTraded = false;
        this.disabledTicker = false;
        this.disabledCollPerContract = false;
        this.disabledCollTotal = false;
      } else if (
        futureContractSpecs != null &&
        futureContractSpecs.CommissionType == "Basis"
      ) {
        this.disabledExpiryDate = true;
        this.disabledSettlementDate = true;
        this.disabledTplus = true;
        this.disabledContractSize = true;
        this.disabledExchangeTraded = true;
        this.disabledTicker = true;
        this.disabledCollPerContract = true;
        this.disabledCollTotal = true;
        this.disabledClientCommTotal = false;
        this.disabledCptyCommTotal = false;
      } else if (
        futureContractSpecs != null &&
        futureContractSpecs.CommissionType == "Spread" &&
        futureContractSpecs.OpenContractType == "Contract"
      ) {
        this.disabledExpiryDate = true;
        this.disabledSettlementDate = true;
        this.disabledTplus = true;
        this.disabledContractSize = true;
        this.disabledExchangeTraded = true;
        this.disabledTicker = true;
        this.disabledCollPerContract = true;
        this.disabledCollTotal = true;
      } else {
        this.disabledExpiryDate = false;
        this.disabledSettlementDate = false;
        this.disabledTplus = false;
        this.disabledContractSize = false;
        this.disabledExchangeTraded = false;
        this.disabledTicker = false;
        this.disabledCollPerContract = false;
        this.disabledCollTotal = false;
      }
      this.arrangeInputFormats();
    },
    arrangeTicker() {
      if (this.tradeForm.contractName != null) {
        // if(this.tradeForm.contractName == "LME Copper" && this.tradeForm.expiryDate != null){
        //   this.tradeForm.ticker = "LMCADP " + this.tradeForm.expiryDate + " LME Comdty";
        // } else if(this.tradeForm.contractName == "LME Tin" && this.tradeForm.expiryDate != null){
        //   this.tradeForm.ticker = "LMSNDP " + this.tradeForm.expiryDate + " LME Comdty";
        // } else if(this.tradeForm.contractName == "LME Nickel" && this.tradeForm.expiryDate != null){
        //   this.tradeForm.ticker = "LMNIDP " + this.tradeForm.expiryDate + " LME Comdty";
        // } else if(this.tradeForm.contractName == "LME Alumunium" && this.tradeForm.expiryDate != null){
        //   this.tradeForm.ticker = "LMAHDP " + this.tradeForm.expiryDate + " LME Comdty";
        // } else if(this.tradeForm.contractName == "LME Zinc" && this.tradeForm.expiryDate != null){
        //   this.tradeForm.ticker = "LMZSDP " + this.tradeForm.expiryDate + " LME Comdty";
        // } else if(this.tradeForm.contractName == "VOB BIST 30"){
        // }
      }
    },
    arrangeVobAccountCommission(futureContractSpecs) {
      if (
        futureContractSpecs != null &&
        futureContractSpecs.OpenContractType == "Contract"
      ) {
        // we dont know VobComissionAccount ask mr mehmet
      }
    },
    arrangeCollateralAndCommission() {
      this.convertNumberInputFormat();

      this.arrangeTotalColl();
      this.arrangeTotalClientCommission();
      this.arrangeTotalCptyCommission();
      this.arrangeNetCommission();
      this.arrangeSpreadGain();
      this.arrangeTotalTcProfit();

      this.arrangeInputFormats();
    },

    convertNumberInputFormat() {
      if (this.tradeForm.collPerContract != null) {
        this.tradeForm.collPerContract = parseFloat(
          this.tradeForm.collPerContract
        );
      }
      if (this.tradeForm.collTotal != null) {
        this.tradeForm.collTotal = parseFloat(this.tradeForm.collTotal);
      }
      if (this.tradeForm.clientCommPerContract != null) {
        this.tradeForm.clientCommPerContract = parseFloat(
          this.tradeForm.clientCommPerContract
        );
      }
      if (this.tradeForm.clientCommTotal != null) {
        this.tradeForm.clientCommTotal = parseFloat(
          this.tradeForm.clientCommTotal
        );
      }
      if (this.tradeForm.clientCommTotal != null) {
        this.tradeForm.clientCommTotal = parseFloat(
          this.tradeForm.clientCommTotal
        );
      }
      if (this.tradeForm.clientPrice != null) {
        this.tradeForm.clientPrice = parseFloat(this.tradeForm.clientPrice);
      }
      if (this.tradeForm.exchangeFee != null) {
        this.tradeForm.exchangeFee = parseFloat(this.tradeForm.exchangeFee);
      }
      if (this.tradeForm.executionPrice != null) {
        this.tradeForm.executionPrice = parseFloat(
          this.tradeForm.executionPrice
        );
      }
      if (this.tradeForm.cptyCommPerContract != null) {
        this.tradeForm.cptyCommPerContract = parseFloat(
          this.tradeForm.cptyCommPerContract
        );
      }
      if (this.tradeForm.cptyCommTotal != null) {
        this.tradeForm.cptyCommTotal = parseFloat(this.tradeForm.cptyCommTotal);
      }
      if (this.tradeForm.netCommission != null) {
        this.tradeForm.netCommission = parseFloat(this.tradeForm.netCommission);
      }
      if (this.tradeForm.spreadGain != null) {
        this.tradeForm.spreadGain = parseFloat(this.tradeForm.spreadGain);
      }
      if (this.tradeForm.totalTcProfit != null) {
        this.tradeForm.totalTcProfit = parseFloat(this.tradeForm.totalTcProfit);
      }
      if (this.tradeForm.spotRef != null) {
        this.tradeForm.spotRef = parseFloat(this.tradeForm.spotRef);
      }
      if (this.tradeForm.contractSize != null) {
        this.tradeForm.contractSize = parseFloat(this.tradeForm.contractSize);
      }
    },
    arrangeInputFormats() {
      if (this.tradeForm.collPerContract != null) {
        this.tradeForm.collPerContract = Number.parseFloat(
          this.tradeForm.collPerContract
        ).toFixed(2);
      }
      if (this.tradeForm.collTotal != null) {
        this.tradeForm.collTotal = Number.parseFloat(
          this.tradeForm.collTotal
        ).toFixed(2);
      }
      if (this.tradeForm.clientCommPerContract != null) {
        this.tradeForm.clientCommPerContract = Number.parseFloat(
          this.tradeForm.clientCommPerContract
        ).toFixed(2);
      }
      if (this.tradeForm.clientCommTotal != null) {
        this.tradeForm.clientCommTotal = Number.parseFloat(
          this.tradeForm.clientCommTotal
        ).toFixed(2);
      }
      if (this.tradeForm.clientPrice != null) {
        this.tradeForm.clientPrice = Number.parseFloat(
          this.tradeForm.clientPrice
        ).toFixed(2);
      }
      if (this.tradeForm.exchangeFee != null) {
        this.tradeForm.exchangeFee = Number.parseFloat(
          this.tradeForm.exchangeFee
        ).toFixed(2);
      }
      if (this.tradeForm.executionPrice != null) {
        this.tradeForm.executionPrice = Number.parseFloat(
          this.tradeForm.executionPrice
        ).toFixed(2);
      }
      if (this.tradeForm.cptyCommPerContract != null) {
        this.tradeForm.cptyCommPerContract = Number.parseFloat(
          this.tradeForm.cptyCommPerContract
        ).toFixed(2);
      }
      if (this.tradeForm.cptyCommTotal != null) {
        this.tradeForm.cptyCommTotal = Number.parseFloat(
          this.tradeForm.cptyCommTotal
        ).toFixed(2);
      }
      if (this.tradeForm.netCommission != null) {
        this.tradeForm.netCommission = Number.parseFloat(
          this.tradeForm.netCommission
        ).toFixed(2);
      }
      if (this.tradeForm.spreadGain != null) {
        this.tradeForm.spreadGain = Number.parseFloat(
          this.tradeForm.spreadGain
        ).toFixed(2);
      }
      if (this.tradeForm.totalTcProfit != null) {
        this.tradeForm.totalTcProfit = Number.parseFloat(
          this.tradeForm.totalTcProfit
        ).toFixed(2);
      }
      if (this.tradeForm.spotRef != null) {
        this.tradeForm.spotRef = Number.parseFloat(
          this.tradeForm.spotRef
        ).toFixed(2);
      }
      if (this.tradeForm.contractSize != null) {
        this.tradeForm.contractSize = Number.parseFloat(
          this.tradeForm.contractSize
        ).toFixed(2);
      }
    },

    getContractField(contractName, columnName) {
      if(this.oldContractFieldName != contractName){
          return new Promise((resolve, reject) => {
          axiosIns
            .post(`searchFutureContractSpecs`, { contractName, columnName })
            .then((res) => {
              if (res.data.specsData != null) {
                if (res.data.resContractSpecs != null) {
                  this.oldContractFieldName = res.data.specsData.ContractName;
                  this.getContractFieldData = {
                    resContractSpecs: res.data.resContractSpecs,
                    specsData: res.data.specsData,
                  }
                  resolve(this.getContractFieldData.specsData);
                } else {
                  this.oldContractFieldName = null;
                  this.getContractFieldData = {
                    resContractSpecs: "Empty",
                    specsData: res.data.specsData,
                  }
                  resolve(this.getContractFieldData.specsData);
                }
              } else {
                this.oldContractFieldName = null;
                this.getContractFieldData = { resContractSpecs: "NoContract", specsData: null };
                resolve(this.getContractFieldData.specsData);
              }
            })
            .catch((error) => reject(error));
        });
      }
      
    },

    //********************************************** END  Calculate func. ***********************/

    resetTradeModal() {
      this.checkedHolidayDate = null;
      this.optSecurityDescriptions = [];
      this.optPriceTypes = [];
      this.optExchangeTrades = [];
      this.optCounterPartyTraders = [];
      this.optScfUsers = [];
      this.optOrderTakenVias = [];
      this.optOrderGivenThroughs = [];
      this.optContractNames = [];
      this.oldTradeFormData = {};
      this.actionsData = [];
      this.checkVerify = false;
      this.getContractFieldData = null;
      this.oldContractFieldName = null;
      //
      this.tradeForm = {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        //Contract details
        buySell: "Buy",
        contractName: null,
        numberOfContracts: null,
        contractSize: null,
        clientPrice: null,
        contractCcy: null,
        executionPrice: null,
        executionPriceCcy: null,
        exchangeFee: null,
        exchangeFeeCcy: null,
        expiryDate: null,
        expiryDateName: null,
        exchangeTraded: null,
        ticker: null,
        collPerContract: null,
        collPerContractCcy: null,
        collTotal: null,
        collTotalCcy: null,
        orderType: "Limit",
        clientCommPerContract: null,
        clientCommPerContractCcy: null,
        clientCommTotal: null,
        clientCommTotalCcy: null,
        cptyCommPerContract: null,
        cptyCommPerContractCcy: null,
        cptyCommTotal: null,
        cptyCommTotalCcy: null,
        netCommission: null,
        netCommissionCcy: null,
        spreadGain: null,
        spreadGainCcy: null,
        totalTcProfit: null,
        totalTcProfitCcy: null,
        //order details
        orderDurationDate: moment().format("DD-MM-YYYY "),
        orderDurationDateName: null,
        orderDurationTime: moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString(),
        entryType: "Order",
        buySell: "Buy",
        orderType: "Limit",
        timeInForce: "DAY",
        orderDate: moment().format("DD-MM-YYYY"),
        orderDateName: null,
        orderTime: moment().format("HH:mm:ss"),
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName: null,
        settlementDate: moment().format("DD-MM-YYYY"),
        settlementDateName: null,
        noticeDate:null,
        noticeDateName:null,
        Tplus: null,
        executionTime: null,
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: "PRINCIPAL",
        hedgeSpec: "HEDGE",
        settlementType: "CASH",
        spotRef: null,
        note: null,
        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        counterpartyRef: null,
        isin: null,
        tradeRef: null,
      };

      this.amendTradeModal = false;
      this.fetchedTradeData = {};
      this.tradeModalLoading = false;
    },

    async tradeEnterReasonText() {
      const self = this;
      const { value: reason } = await this.$swal({
        title: self.$t("swap_future.reason.amendment_reason"),
        text: self.$t("swap_future.reason.please_enter_amendment_reason"),
        input: "textarea",
        inputPlaceholder: self.$t(
          "swap_future.reason.please_enter_amendment_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("swap_future.reason.submit"),
        cancelButtonText: self.$t("swap_future.reason.Cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              self.tradeForm.amendmentReason = reason;
              resolve();
            } else {
              resolve(self.$t("swap_future.reason.you_need_write_reason"));
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      });

      if (reason) {
        return new Promise(function(resolve, reject) {
          resolve(true);
        });
      }
    },
    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    exitAmendOperationMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("swap_future.messages.trade_amend_cancelled"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    validReasonTextMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("swap_future.reason.enter_valid_reason"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    greaterThanZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `${val} must be greater than 0`,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedEnableOCOInputsMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("swap_future.messages.required_fill_inputs") +
              ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("swap_future.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("swap_future.general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    noChangesMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("swap_future.messages.not_changes"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkedTraSettlDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalCreatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkTimeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkAmendEntryTypeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    tradeUpdatedSuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("swap_future.toast_messages.trade_update_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    formatPrice2(value, exp) {
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },
    setTradeData() {
      if (
        this.tradeForm.timeInForce == "GTC" ||
        this.tradeForm.timeInForce == "FOK" ||
        this.tradeForm.timeInForce == "FAK"
      ) {
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }

      let setTrade = {
        costCenter: this.tradeForm.costCenter,
        client: this.tradeForm.client != null ? this.tradeForm.client : null,
        clientAcc:
          this.tradeForm.clientAccount != null
            ? this.tradeForm.clientAccount
            : null,
        cpty:
          this.tradeForm.counterparty != null
            ? this.tradeForm.counterparty
            : null,
        cptyAcc:
          this.tradeForm.cptyAccount != null
            ? this.tradeForm.cptyAccount
            : null,
        cptyBuySell:
          this.tradeForm.buySell != null ? this.tradeForm.buySell : null,
        contractName:
          this.tradeForm.contractName != null
            ? this.tradeForm.contractName
            : null,
        numberOfContracts:
          this.tradeForm.numberOfContracts != null
            ? parseFloat(this.tradeForm.numberOfContracts)
            : null,
        contractSize:
          this.tradeForm.contractSize != null
            ? parseFloat(this.tradeForm.contractSize)
            : null,
        clientPrice:
          this.tradeForm.clientPrice != null
            ? parseFloat(this.tradeForm.clientPrice)
            : null,
        ccy:
          this.tradeForm.contractCcy != null
            ? this.tradeForm.contractCcy
            : null,
        executionPrice:
          this.tradeForm.executionPrice != null
            ? parseFloat(this.tradeForm.executionPrice)
            : null,
        exchangeFee:
          this.tradeForm.exchangeFee != null
            ? parseFloat(this.tradeForm.exchangeFee)
            : null,
        expiryDate:
          this.tradeForm.expiryDate != null
            ? this.YMDdateFormat(this.tradeForm.expiryDate)
            : null,
        exchangeTraded:
          this.tradeForm.exchangeTraded != null
            ? this.tradeForm.exchangeTraded
            : null,
        ticker: this.tradeForm.ticker != null ? this.tradeForm.ticker : null,
        collPerContract:
          this.tradeForm.collPerContract != null
            ? parseFloat(this.tradeForm.collPerContract)
            : null,
        collTotal:
          this.tradeForm.collTotal != null
            ? parseFloat(this.tradeForm.collTotal)
            : null,
        clientCommPerContract:
          this.tradeForm.clientCommPerContract != null
            ? parseFloat(this.tradeForm.clientCommPerContract)
            : null,
        clientCommTotal:
          this.tradeForm.clientCommTotal != null
            ? parseFloat(this.tradeForm.clientCommTotal)
            : null,
        cptyCommPerContract:
          this.tradeForm.cptyCommPerContract != null
            ? parseFloat(this.tradeForm.cptyCommPerContract)
            : null,
        cptyCommTotal:
          this.tradeForm.cptyCommTotal != null
            ? parseFloat(this.tradeForm.cptyCommTotal)
            : null,
        netCommission:
          this.tradeForm.netCommission != null
            ? parseFloat(this.tradeForm.netCommission)
            : null,
        spreadGain:
          this.tradeForm.spreadGain != null
            ? parseFloat(this.tradeForm.spreadGain)
            : null,
        totalTcProfit:
          this.tradeForm.totalTcProfit != null
            ? parseFloat(this.tradeForm.totalTcProfit)
            : null,
        entryType: this.tradeForm.entryType,
        orderType: this.tradeForm.orderType,
        timeInForce: this.tradeForm.timeInForce,
        orderDate:
          this.tradeForm.orderDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDate)
            : null,
        orderDurationDate:
          this.tradeForm.orderDurationDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDate)
            : null,
        orderDurationTime:
          this.tradeForm.orderDurationTime != null
            ? this.timeFormat(this.tradeForm.orderDurationTime)
            : null,
        tradeDate:
          this.tradeForm.tradeDate != null
            ? this.YMDdateFormat(this.tradeForm.tradeDate)
            : null,
        settDate:
          this.tradeForm.settlementDate != null
            ? this.YMDdateFormat(this.tradeForm.settlementDate)
            : null,
        noticeDate:this.tradeForm.noticeDate != null
            ? this.YMDdateFormat(this.tradeForm.noticeDate)
            : null,
        orderTime:
          this.tradeForm.orderTime != null
            ? this.timeFormat(this.tradeForm.orderTime)
            : null,
        execTime:
          this.tradeForm.executionTime != null
            ? this.timeFormat(this.tradeForm.executionTime)
            : null,
        counterpartyTrader:
          this.tradeForm.counterpartyTrader != null
            ? this.tradeForm.counterpartyTrader
            : null,
        tcUser: this.tradeForm.scfUser != null ? this.tradeForm.scfUser : null,
        orderTakenVia:
          this.tradeForm.orderTakenVia != null
            ? this.tradeForm.orderTakenVia
            : null,
        orderGivenThrough:
          this.tradeForm.orderGivenThrough != null
            ? this.tradeForm.orderGivenThrough
            : null,
        actingCap:
          this.tradeForm.actingCapaticy != null
            ? this.tradeForm.actingCapaticy
            : null,
        hedgeSpec:
          this.tradeForm.hedgeSpec != null ? this.tradeForm.hedgeSpec : null,
        settlementType:
          this.tradeForm.settlementType != null
            ? this.tradeForm.settlementType
            : null,
        spotRef: this.tradeForm.spotRef != null ? this.tradeForm.spotRef : null,
        note: this.tradeForm.note != null ? this.tradeForm.note : null,
        confirmationDate:
          this.tradeForm.confirmationDate != null
            ? this.YMDdateFormat(this.tradeForm.confirmationDate)
            : null,
        confirmationTime:
          this.tradeForm.confirmationTime != null
            ? this.timeFormat(this.tradeForm.confirmationTime)
            : null,
        confirmationTrader:
          this.tradeForm.confirmationTrader != null
            ? this.tradeForm.confirmationTrader
            : null,
        confirmationDateClient:
          this.tradeForm.confirmationDateClient != null
            ? this.YMDdateFormat(this.tradeForm.confirmationDateClient)
            : null,
        confirmationTimeClient:
          this.tradeForm.confirmationTimeClient != null
            ? this.timeFormat(this.tradeForm.confirmationTimeClient)
            : null,
        cptyUti: this.tradeForm.cptyUti != null ? this.tradeForm.cptyUti : null,
        uniqueLinkId:
          this.tradeForm.uniqueLinkID != null
            ? this.tradeForm.uniqueLinkID
            : null,
        cpRef:
          this.tradeForm.counterpartyRef != null
            ? this.tradeForm.counterpartyRef
            : null,
        isin: this.tradeForm.isin != null ? this.tradeForm.isin : null,
        tcUti: this.tradeForm.tcUti != null ? this.tradeForm.tcUti : null,
        amendmentReason: this.tradeForm.amendmentReason,
        ID: this.tradeForm.tradeRef,

        // savedBy: this.user.name ? this.user.name + ' ' + this.user.surname : null,
        // saveDate: moment().format("YYYY-MM-DD"),
        // saveTime: moment().format("HH:mm:ss"),
      };

      return setTrade;
    },
  },
  watch: {
    amendTradeModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeSwapFutureTCUsers();
          this.getArrangeSwapFutureCurrencies();
          this.getArrangeSwapFutureOrderTakenGivenThrough();
          this.getAllExchanges();
          this.getFutureContractSpecs();

          //   this.tradeForm.scfUser = this.user.name;
          //   this.tradeForm.orderDurationDate = moment().format("DD-MM-YYYY ");
          //   this.tradeForm.orderDurationTime = moment()
          //     .endOf("day")
          //     .format("HH:mm:ss")
          //     .toString();

          //   this.tradeForm.entryType = "Order";
          //   this.tradeForm.buySell = "Buy";
          //   this.tradeForm.orderType = "Limit";
          //   this.tradeForm.timeInForce = "DAY";
          //   this.tradeForm.orderDate = moment().format("DD-MM-YYYY");
          //   this.tradeForm.orderTime = moment().format("HH:mm:ss");
          //   this.tradeForm.actingCapaticy = "PRINCIPAL";
          //   this.tradeForm.instrumentType = "EQ";
        }
      },
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = {
            costCenter: val.CostCenter,
            client: val.Client ?? null,
            clientAccount: val.ClientAcc ?? null,
            counterparty: val.Cpty ?? null,
            cptyAccount: val.CptyAcc ?? null,
            contractName: val.ContractName ?? null,
            numberOfContracts:
              val.NumberOfContracts != null
                ? Number(val.NumberOfContracts)
                : null,
            contractSize:
              val.ContractSize != null ? parseFloat(val.ContractSize) : null,
            contractCcy: val.Ccy ?? null,
            clientPrice:
              val.ClientPrice != null ? parseFloat(val.ClientPrice) : null,
            executionPrice:
              val.ExecutionPrice != null
                ? parseFloat(val.ExecutionPrice)
                : null,
            exchangeFee:
              val.ExchangeFee != null ? parseFloat(val.ExchangeFee) : null,
            expiryDate:
              val.ExpiryDate != null ? this.dateFormat(val.ExpiryDate) : null,
            exchangeTraded: val.ExchangeTraded ?? null,
            ticker: val.Ticker ?? null,
            collPerContract:
              val.CollPerContract != null
                ? parseFloat(val.CollPerContract)
                : null,
            collTotal: val.CollTotal != null ? parseFloat(val.CollTotal) : null,
            clientCommPerContract:
              val.ClientCommPerContract != null
                ? parseFloat(val.ClientCommPerContract)
                : null,
            clientCommTotal:
              val.ClientCommTotal != null
                ? parseFloat(val.ClientCommTotal)
                : null,
            cptyCommPerContract:
              val.CptyCommPerContract != null
                ? parseFloat(val.CptyCommPerContract)
                : null,
            cptyCommTotal:
              val.CptyCommTotal != null ? parseFloat(val.CptyCommTotal) : null,
            netCommission: val.NetComm != null ? parseFloat(val.NetComm) : null,
            spreadGain:
              val.SpreadGain != null ? parseFloat(val.SpreadGain) : null,
            totalTcProfit:
              val.TotalTcProfit != null ? parseFloat(val.TotalTcProfit) : null,
            orderDurationDate:
              val.OrderDurationDate != null
                ? this.dateFormat(val.OrderDurationDate)
                : null,
            orderDurationTime:
              val.OrderDurationTime != null ? val.OrderDurationTime : null,
            entryType: val.EntryType ?? null,
            buySell: val.CptyBuySell ?? null,
            orderType: val.OrderType ?? null,
            timeInForce: val.TimeinForce ?? null,
            orderDate:
              val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
            orderTime: val.OrderTime != null ? val.OrderTime : null,
            tradeDate:
              val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
            settlementDate:
              val.SettDate != null ? this.dateFormat(val.SettDate) : null,
            noticeDate:
              val.NoticeDate != null ? this.dateFormat(val.NoticeDate) : null,
            executionTime: val.ExecTime != null ? val.ExecTime : null,
            counterpartyTrader: val.CptyTrader ?? null,
            scfUser: val.TcUser ?? null,
            orderTakenVia: val.OrderTakenVia ?? null,
            orderGivenThrough: val.OrderGivenThrough ?? null,
            actingCapaticy: val.ActingCap ?? null,
            counterpartyRef: val.CpRef ?? null,
            hedgeSpec: val.HedgeSpec ?? null,
            settlementType: val.SettlementType ?? null,
            spotRef: val.SpotRef != null ? parseFloat(val.SpotRef) : null,
            note: val.TraderNote ?? null,
            confirmationDate:
              val.ConfirmationDate != null
                ? this.dateFormat(val.ConfirmationDate)
                : null,
            confirmationTime:
              val.ConfirmationTime != null ? val.ConfirmationTime : null,
            confirmationTrader: val.ConfirmationTrader ?? null,
            confirmationDateClient:
              val.ConfirmationDateClient != null
                ? this.dateFormat(val.ConfirmationDateClient)
                : null,
            confirmationTimeClient:
              val.ConfirmationTimeClient != null
                ? val.ConfirmationTimeClient
                : null,
            cptyUti: val.CptyUti ?? null,
            tcUti: val.TcUti ?? null,
            uniqueLinkID: val.UniqueLinkId ?? null,
            isin: val.Isin ?? null,
            tradeRef: val.id,
          };

          this.changeTimeInForceType();
          this.copyCurrencies();

          //set trade && settle date && expiry date name
          let tradeDate = new Date(
            this.MDYdateFormat(this.tradeForm.tradeDate)
          );
          this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

          let settleDate = new Date(
            this.MDYdateFormat(this.tradeForm.settlementDate)
          );
          this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

          let expiryDate = new Date(
            this.MDYdateFormat(this.tradeForm.expiryDate)
          );
          this.tradeForm.expiryDateName = this.weekday[expiryDate.getDay()];

          this.oldTradeFormData = JSON.parse(JSON.stringify(this.tradeForm));

          this.arrangeInputFormats();

          // hack textare row count
          setTimeout(function() {
            let textarea = document.getElementById("form-textarea");
            if (textarea) {
              textarea.rows = 1;
            }
          }, 200);
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.settlementDateName .input-group-append .input-group-text {
  color: red;
}

.tradeDateName .input-group-append .input-group-text {
  color: red;
}

.expiryDateName .input-group-append .input-group-text {
  color: red;
}

.noticeDateName .input-group-append .input-group-text {
  color: red;
}

.animate__animated {
  animation-fill-mode: none;
}

.input-dropdown .btn {
  padding: 0;
}

.swall2-overlay {
  z-index: 100005;
}
.swal2-modal {
  z-index: 99999;
}

#actions-popover {
  width: 500px !important;
  max-width: 500px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";
</style>
